import React from 'react'

export default {
dontIncludeEasydone:()=>{
    return(<div> <a href="https://dugoly.com/managementInterface/uploader" >Invalid image, upload image in easydone interface only! <br />
    you can use this link : https://dugoly.com/managementInterface/uploader <br />
    or click here
    </a>
     </div>)
},
discrepancies:()=>{return(<div>Invalid image, The system supports 970 * 90 PX image only! <br />
 More details can be found on 
 <a href="https://dugoly.com/Documentation/readme/dugoly-shared-advertising.html" >  dugoly's website </a> 
 </div>)},
    
localhost:(link,img)=>{
        return(<a href={link} ><img src={img} width={window.innerWidth>970?"970px":window.innerWidth} style={{maxHeight:"90px"}}/></a>)},
ads:(Path,Link,onclickReporting,callBackRef,Token)=>{
    return (<div>
        {
        (Path&&Link)?<a href={Link} target="blank" onClick={()=>{onclickReporting(Token,Link)}} ><img src={Path} width={window.innerWidth>970?"970px":window.innerWidth} height={window.innerWidth>=970?90:(window.innerWidth/970)*90}  ref={callBackRef}/></a>:
        null
        }
        </div>)
}
}