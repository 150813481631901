import React, {useState, useEffect} from "react";
import Button from '@material-ui/core/Button';
// import { FaTag } from 'react-icons/fa';
import Sharing  from './Sharing'
import RssButton from './RssButton'
// import tracker from 'dugoly-tracking'
import Spinner from 'react-spinners/ClimbingBoxLoader'
// import { ReactComponent as FaceBookSvg } from "./facebook-brands.svg";


// function Artical({setComponent, postData, dir, rssUrl, system}) {
// const blogRouter=({ownerUid, bCode, BlogTitle, dir, withRouter})=>{
    function Artical(props) {
        console.log("artical>props.postData:  ",props.postData)
    const [Post, setPost]=useState({})
    const [system,setSystem]=useState({})
    const postId=props.match.params.id
    const rssUrl=`https://dugoly.com/api/blog-single/rss/xml/${props.ownerUid}/${props.bCode}`

    useEffect(()=>{ 
        if(props.postData){setPost(props.postData)}
        else{
            fetch("https://dugoly.com/api/getOnePost", {
        // fetch("http://localhost:3002/api/getOnePost", {
    method: "post",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    //make sure to serialize your JSON body
    body: JSON.stringify({    
      domain:"dugoly.com", uid:props.ownerUid, BCode:props.bCode, postId
    })
  }).then(response => response.json())
    .then(data => {
    setPost(data)
})}
if(props.system){setSystem(props.system)}
else{
    // fetch("http://localhost:3002/api/getOnePost", {
        fetch("https://dugoly.com/api/getOnePost", {
    method: "post",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    //make sure to serialize your JSON body
    body: JSON.stringify({    
      domain:"dugoly.com", uid:props.ownerUid, BCode:props.bCode, postId:"system"
    })
  }).then(response => response.json())
    .then(data => {
setSystem(data)
})}




},[])
    // useEffect(() => {
    //     window.scrollTo(0, 0)
    //     if(!postData){

    //     }
    //   }, [])
    
    // if(!postData){return(<div>No data has been received yet</div>)}
    let url = new URL(location.href);
  let directUrl=  url.href
  let blogIds=`${props.ownerUid}/${props.bCode}`
  let mainUrl=(system&&system.link?system.link:"https://blogy.click/blog/"+blogIds)+"/post/"+postId
  
//   tracker(blogIds+'/'+postId,"blog, artical")
const date=new Date(parseInt(postId)).toUTCString()
const BackButton=()=>{
    return(<Button className="loadcontent" 
    onClick={()=>{
        window.location.replace("../")}}
    style={{
        backgroundColor: "#3cc18f",
        color: "white",
        fontSize: "10px",
        border: "none",
        boxSizing:"border-box",
        outline: "none",
        padding: "4px 0px",
        width: "100%",
        textTransform:"capitalize",
        maxWidth: "160px",
        display: "block",
        margin: "20px auto"
    }}>back to blog page</Button>)
}

const Image=()=>{
    return(<div>{Post.imageUrl?<div className="imageArtical" style={{
        width: "100%",
        maxWidth: "600px",
        boxSizing:"border-box",
        height: "300px",
        margin: "auto",
        display: "block"
    }}>
        <img style={{
            width: "inherit",
            boxSizing:"border-box",
            height: "inherit",
            objectFit: "scale-down",
        }} src={Post.imageUrl} alt="" />
    </div>:null}</div>
        )
}
const Summary=()=>{
    return(<div>
        {Post.summary?<div className="imageDis" style={{
        width: "100%",
        maxWidth: "1000px",
        display: "block",
        margin: "auto",
        boxSizing:"border-box",
        textAlign: "center",
        padding: "30px 0px 20px 0px",
        fontFamily: "Segoe UI",
        fontStyle: "italic"
    }}><span style={{
        color: "#6c77ff",
        fontSize: "20px",
        boxSizing:"border-box",
        fontFamily: "sans-serif"
    }}><b><i>Summary :</i></b></span>   {Post.summary}  </div>:null}</div>)
}
    return (
        <div 
        dir={props.dir}
        style={{
            width: document.activeElement.clientWidth<=240 ? "96%" : "100%" ,
            margin:"auto",
            boxSizing:"border-box",
            padding:"0px",
        }}>
        <div className="header" style={{
                width: "100%",
                maxWidth: "1400px",
                margin: "auto",
                textAlign: "center",
                fontFamily: 'Segoe UI',
                fontSize: "20px",
                color: "black",
                boxSizing:"border-box",
                fontWeight: "bold",
                textTransform: "capitalize",
                padding: "8px 0px 10px 0px",
                borderBottom: "2px solid #5381ff",
                display: "inline-flex",
            flexDirection: "row",
            justifyContent: "space-between"
            }}>
               {Post.title}
               <RssButton rssUrl={rssUrl} /> 
        </div>
            <div className="imageContent" style={{
                width: "100%",
                maxWidth: "1400px",
                margin: "auto",
                display: "flex",
                flexDirection: "row",
                boxSizing:"border-box",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
                flexWrap: "wrap"
            }}>
                <div className="imageContentBar" style={{
                    width: "100%",
                    maxWidth: "400px",
                    display: "flex",
                    boxSizing:"border-box",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "center",
                    flexWrap: "wrap"
                }}>
                    <span className="Date" style={{
                        textTransform: "uppercase",
                        fontSize: "12px",
                        fontFamily: "Segoe UI",
                        boxSizing:"border-box",
                        color: "#868789",
                    }}>{date} </span> 
                     <Sharing url={directUrl} text={Post.summary?Post.summary:Post.title}/>
                     </div>
                <div>
                    {/* <span className="ownerName" style={{
                        fontFamily: 'Segoe UI',
                        fontSize: "12px",
                        boxSizing:"border-box",
                        color: "#868789",
                        textTransform: "capitalize"
                    }}>By Ownar</span> */}
                   <BackButton />
                </div>
            </div>
            <Image />
            <Summary />
            <div style={{
                borderBottom: "2px solid #5381ff",
                width: "100%",
                boxSizing:"border-box",
                maxWidth: "1400px",
                margin: "auto"
            }}></div>
            <div className="textContent" style={{
                width: "100%",
                boxSizing:"border-box",
                maxWidth: "1400px",
                padding: "30px 10px 20px 10px",
                display: "block",
                margin: "auto"
            }}>
                <p className="longtextContent" style={{
                    fontFamily: 'Segoe UI',
                    color: "#707070",
                    fontSize: "16px",
                    boxSizing:"border-box",
                    textAlign: "left",
                    margin: "10px 0px 20px 0px"
                }}>
{Post&&Object.keys(Post).length===0&&<center><br /><br /><br /><Spinner /><br /><br /><br />I'm uploading data now, just a moment please</center>}

                    <div
  dangerouslySetInnerHTML={{
    __html: Post.text
  }}></div>
  
                     </p>
                 </div>
            <div 
            dir="ltr"
            style={{
               display: "flex",
               flexDirection: "row",
               justifyContent: "space-between",
               alignItems: "flex-start",
               flexWrap: "wrap",
                width: "100%",
                boxSizing:"border-box",
                backgroundColor: "#f2f5ff",
            }}>
            <a href={mainUrl} >Click here to go to the main presentation of this post</a>
            {/* </div> <br/> */}
{/* תיוגים לשלב שני */}
            {/* <div style={{
                width: "100%",
                boxSizing:"border-box",
                backgroundColor: "#f2f5ff",
            }}> */}
                {/* <div className="tagbar" style={{
                    width: "100%",
                    maxWidth: "1400px",
                    boxSizing:"border-box",
                    margin: "auto",
                    padding: "10px 0px 0px 0px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#f2f5ff",
                    fleWrap: "wrap"
                }}> */}
                    {/* <div className="tag" style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        boxSizing:"border-box",
                        flexWrap: "wrap",
                        width: "100%",
                        maxWidth: "520px",
                        margin: "10px"
                    }}> <FaTag/>   <span style={{
                        color:"#28292d",
                        boxSizing:"border-box",
                        fontFamily:"Segoe UI",
                        fontWeight:"500",
                        fontSize:"20px"
                    }}> Tags: </span> */}
                        {/* <div className="tagNameRed" style={{
                            display: "inline",
margin: "4px",
                            color: "white",
                            boxSizing:"border-box",
                            backgroundColor: "#f65c5c",
                            width:"100%",
                            maxWidth:"80px",
                            fontWeight:"300",
                            textAlign:"center",
                            padding:"4px 0px",
                        }}>Blog</div>
                        <div className="tagNameBlue" style={{
                            display: "inline",
margin: "4px",
                            boxSizing:"border-box",
                            color: "white",
                            width:"100%",
                            maxWidth:"80px",
                            textAlign:"center",
                            padding:"4px 0px",
                            backgroundColor: "#5cb8f6",
                            fontWeight:"300"
                        }}>SEO</div> */}
                        {/* <div className="tagNameBlueD" style={{
                            display: "inline",
margin: "4px",
                            boxSizing:"border-box",
                            color: "white",
                            backgroundColor: "#5381ff",
                            width:"100%",
                            maxWidth:"80px",
                            textAlign:"center",
                            padding:"4px 0px",
                            fontWeight:"300"
                        }}>Bing</div> */}
                        {/* <div className="tagNameY" style={{
                            display: "inline",
margin: "4px",
                            boxSizing:"border-box",
                            color: "white",
                            backgroundColor: "#f6b85c",
                            width:"100%",
                            maxWidth:"80px",
                            textAlign:"center",
                            padding:"4px 0px",
                            fontWeight:"300"
                        }}>Google</div>
                    </div> */}
                    {/* <div className="imageContentBar" style={{
                        width: "100%",
                        maxWidth: "400px",
                        boxSizing:"border-box",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        alignItems: "center",
                        flexWrap: "wrap"
                    }}> */}
                         <Sharing url={directUrl} text={Post.summary?Post.summary:Post.title}/>
                    {/* </div> */}
                {/* </div> */}
            </div>
            {/* <div className="commentContent" style={{
                width: "100%",
                maxWidth: "1400px",
                boxSizing:"border-box",
                margin: "auto",
                padding: "30px 0px",
                borderBottom: "2px solid #5381ff"
            }}>
                <Button className="showcommentbtn" style={{
                    width: "100%",
                    maxWidth: "100px",
                    padding: "8px 0px",
                    border: "none",
                    outline: "none",
                    boxSizing:"border-box",
                    textTransform:"capitalize",
                    backgroundColor: "#53a9ff",
                    color: "white",
                    fontSize: "10px"
                }}>Show Comment</Button>
                <div className="commenttitle" style={{
                    fontSize: "25px",
                    boxSizing:"border-box",
                    fontFamily: 'Segoe UI',
                    color: "black",
                    margin: "8px 0px"
                }}>Comments</div>
                <div className="commentmake" style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    boxSizing:"border-box",
                    width: "100%",
                    maxWidth: "700px",
                    fleWrap: "wrap",
                }}>
                    <div className="userimage" style={{
                        width: "100%",
                        maxWidth: "120px",
                        display: "block",
                        boxSizing:"border-box",
                    }}>
                        <img className="commentmakeimg" style={{
                            width: "100px",
                            objectFit: "fill",
                            display: "block",
                            boxSizing:"border-box",
                            margin: "auto",
                            borderRadius: "10px",
                            overflow: "hidden"
                        }} src="http://www.easydone.online/uploads/fls/8391ea6b-c196-4fbe-a437-2594928f13f3.jpeg" alt="" />
                        <p className="imagetitle" style={{
                            textAlign: "center",
                            boxSizing:"border-box",
                            fontSize: "20px",
                            fontFamily: 'Segoe UI',
                            color: "black"
                        }}>Selena</p>
                    </div>
                    <div className="commentwrite" style={{
                        width: "100%",
                        boxSizing:"border-box",
                        maxWidth: "540px"
                    }}>
                        <textarea className="commenttext" rows="6" style={{
                            width: "100%",
                            boxSizing:"border-box",
                            borderRadius: "6px",
                            border: "2px solid #5381ff"
                        }} />
                        <Button className="addcommentbtn" style={{
                            width: "100%",
                            maxWidth: "80px",
                            border: "none",
                            outline: "none",
                            boxSizing:"border-box",
                            padding: "4px 0px",
                            textAlign: "center",
                            textTransform:"capitalize",
                            fontFamily: 'Segoe UI',
                            color: "white",
                            backgroundColor: "#5381ff",
                            fontSize: "10px",
                            marginLeft: "auto",
                            marginTop: "4px",
                            display: "block"
                        }}>Add Comment</Button>
                    </div>
                </div>
                <div className="commentRead" style={{
                    boxSizing:"border-box",
                    margin:"40px 4px 0px 4px",
                }}>
                    <div className="mainComment" style={{
                        width: "100%",
                        boxSizing:"border-box",
                        maxWidth: "524px",
                    }}>
                        <div className="com" style={{
                            display: "flex",
                            flexDirection: "row",
                            boxSizing:"border-box",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            maxWidth: "400px",
                            width: "100%"
                        }}>
                            <div className="comimgs" style={{
                                width: "100%",
                                boxSizing:"border-box",
                                maxWidth: "60px"
                            }}>
                                <img className="comimg" style={{
                                    width: "inherit",
                                    height: "inherit",
                                    boxSizing:"border-box",
                                    objectFit: "fill",
                                    borderRadius:"10px"
                                }} src="http://www.easydone.online/uploads/fls/8391ea6b-c196-4fbe-a437-2594928f13f3.jpeg" alt="" />
                            </div>
                            <div className="comcontent" style={{
                                display: "flex",
                                width: "100%",
                                boxSizing:"border-box",
                                maxWidth: "280px",
                                flexDirection: "column"
                            }}>
                                <div className="contenttex" style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    boxSizing:"border-box",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}>
                                    <span className="comtex" style={{
                                        fontSize: "16px",
                                        boxSizing:"border-box",
                                        fontFamily: 'Segoe UI',
                                        color: "black",
                                        fontWeight: "bold"
                                    }}>Sophin</span>
                                    <span className="comtexdate" style={{
                                        fontSize: "12px",
                                        boxSizing:"border-box",
                                        fontFamily: 'Segoe UI',
                                        color: "black",
                                        fontWeight: "600"
                                    }}>20 NOV 2020</span>
                                    <Button className="editbtn" style={{
                                        border: "none",
                                        outline: "none",
                                        boxSizing:"border-box",
                                        textTransform:"capitalize",
                                        backgroundColor: "transparent",
                                        color: "#5381ff"
                                    }}>Edit</Button>
                                </div>
                                <div className="comtextw" style={{
                                    fontSize: "18px",
                                    fontFamily: 'Segoe UI',
                                    boxSizing:"border-box",
                                    color: "black",
                                    fontweight: "400",
                                    marginTop: "4px"
                                }}> Lorem Ipsum is simply typesetting </div>
                                <div className="combtns" style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    boxSizing:"border-box",
                                    marginTop: "16px",
                                    width: "100%",
                                    maxWidth: "160px",
                                    justifyContent: "space-between",
                                }}>
                                    <Button className="replaybtn" style={{
                                        width: "100%",
                                        maxWidth: "70px",
                                        fontSize: "10px",
                                        color: "white",
                                        backgroundColor: "#53c4ff",
                                        padding: "4px 0px",
                                        boxSizing:"border-box",
                                        textTransform:"capitalize",
                                        border: "none",
                                        outline: "none",
                                        borderRadius: "2px"
                                    }} >Replay</Button>
                                    <Button className="deletebtn" style={{
                                        width: "100%",
                                        maxWidth: "70px",
                                        boxSizing:"border-box",
                                        fontSize: "10px",
                                        textTransform:"capitalize",
                                        color: "white",
                                        backgroundColor: "#f65c5c",
                                        padding: "4px 0px",
                                        border: "none",
                                        outline: "none",
                                        borderRadius: "2px"
                                    }}>Delete</Button>
                                </div>
                            </div>
                        </div>
                        <div className="rep" style={{
                            display: "flex",
                            flexDirection: "row",
                            boxSizing:"border-box",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            maxWidth: "400px",
                            width: "100%",
                            marginLeft: "auto",
                            marginTop: "60px"
                        }}>
                            <div className="comimgs" style={{
                                width: "100%",
                                boxSizing:"border-box",
                                maxWidth: "60px"
                            }}>
                                <img className="comimg" style={{
                                    width: "inherit",
                                    height: "inherit",
                                    boxSizing:"border-box",
                                    objectFit: "scale-down",
                                    borderRadius:"10px"
                                }} src="http://www.easydone.online/uploads/fls/8391ea6b-c196-4fbe-a437-2594928f13f3.jpeg" alt="" />
                            </div>
                            <div className="comcontent" style={{
                                display: "flex",
                                boxSizing:"border-box",
                                width: "100%",
                                maxWidth: "280px",
                                flexDirection: "column"
                            }}>
                                <div className="contenttex" style={{
                                    display: "flex",
                                    boxSizing:"border-box",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}>
                                    <span className="comtex" style={{
                                        fontSize: "16px",
                                        boxSizing:"border-box",
                                        fontFamily: 'Segoe UI',
                                        color: "black",
                                        fontWeight: "bold"
                                    }}>Sophin</span>
                                    <span className="comtexdate" style={{
                                        fontSize: "12px",
                                        fontFamily: 'Segoe UI',
                                        boxSizing:"border-box",
                                        color: "black",
                                        fontWeight: "600"
                                    }}>20 NOV 2020</span>
                                    <Button className="editbtn" style={{
                                        border: "none",
                                        boxSizing:"border-box",
                                        outline: "none",
                                        backgroundColor: "transparent",
                                        textTransform:"capitalize",
                                        color: "#5381ff"
                                    }}>Edit</Button>
                                </div>
                                <div className="comtextw" style={{
                                    fontSize: "18px",
                                    fontFamily: 'Segoe UI',
                                    boxSizing:"border-box",
                                    color: "black",
                                    fontweight: "400",
                                    marginTop: "4px"
                                }}> Lorem Ipsum is simply typesetting </div>

                                <div className="combtns" style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    marginTop: "16px",
                                    width: "100%",
                                    boxSizing:"border-box",
                                    maxWidth: "160px",
                                    justifyContent: "space-between",
                                }}>
                                    <Button className="replaybtn" style={{
                                        width: "100%",
                                        maxWidth: "70px",
                                        fontSize: "10px",
                                        boxSizing:"border-box",
                                        color: "white",
                                        backgroundColor: "#53c4ff",
                                        padding: "4px 0px",
                                        textTransform:"capitalize",
                                        border: "none",
                                        outline: "none",
                                        borderRadius: "2px"
                                    }} >Replay</Button>
                                    <Button className="deletebtn" style={{
                                        width: "100%",
                                        maxWidth: "70px",
                                        fontSize: "10px",
                                        boxSizing:"border-box",
                                        color: "white",
                                        textTransform:"capitalize",
                                        backgroundColor: "#f65c5c",
                                        padding: "4px 0px",
                                        border: "none",
                                        outline: "none",
                                        borderRadius: "2px"
                                    }} >Delete</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

            {/* </div> */}
            
            <div className="commentContent" style={{
                width: "100%",
                maxWidth: "1400px",
                boxSizing:"border-box",
                margin: "auto",
                padding: "30px 0px",
                borderBottom: "2px solid #5381ff"
            }}><BackButton /></div>
            <div   dir="ltr">
            <h6>Component by: <a href="https://dugoly.com/">dugoly - developers tools</a>, developed by: <a href="https://motto.co.il/">motto</a> </h6>
        </div></div>
    )
}

export default Artical